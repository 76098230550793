* a {
  color: #ef4e57;
}

h5,
.h5 {
  color: #ef4e57;
  text-transform: none;
}

.icRight {
  position: relative;
  margin-top: 15px;
}

.hidebutton {
  border: #fff !important;
  background-color: #fff !important;
}

/******************Anup Grid css***********/

.TableDynamic .react-bootstrap-table .table {
  table-layout: auto;
  width: 1200px;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  /* border: 1px solid #ddd; */
}

.topmargin30 {
  margin-top: 31px !important;
}

.txtpagebreak {
  page-break-before: always;
}

.page-main-header .main-header-right svg line {
  color: #2f3c4e;
}

.page-main-header .main-header-right .nav-right ul li svg {
  color: #ef4e57;
}

.page-main-header .main-header-right .nav-right ul li .dot {
  background-color: #ef4e57;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .animate-circle {
  border: 5px solid #ef4e57;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .main-circle {
  background-color: #ef4e57;
}

.card .card-header h5 {
  text-transform: none;
  font-weight: 400;
}

.form-group {
  margin-bottom: 25px;
}

.form-control {
  border-color: #dee2e6 !important;
}

.pagination-primary .page-item .page-link {
  color: #2f3c4e;
}

.pagination-primary .page-item.active .page-link {
  color: #fff;
  background-color: #ef4e57;
  border-color: #ef4e57;
}

.checkbox_animated:before {
  border-color: #ef4e57;
}

.radio_animated:before {
  background: #ef4e57;
}

.radio_animated:after {
  border: 2px solid #ced4da;
}


.authentication-box .btn-primary {
  background-color: #2f3c4e !important;
  border-color: #2f3c4e !important;
}

.swal2-styled.swal2-confirm {
  background-color: #ef4e57 !important;
}

.swal2-styled.swal2-cancel {
  background-color: #2f3c4e !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown {
  width: 168px;
  top: 50px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(2) {
  border-top: 1px solid #eff0f1;
  padding-top: 20px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown h6 {
  color: #313131;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 3px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 0;
  color: #ef4e57;
  padding-bottom: 15px;
}

#stepRws .footer-buttons {
  text-align: center;
  margin-top: 15px;
}



.dzu-dropzone {
  border: 2px dashed #2f3c4e !important;
  background: rgba(0, 0, 0, 0.1);
}

.dzu-inputLabel {
  color: #2f3c4e !important;
}



.pb0 {
  padding-bottom: 0px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb0 {
  margin-bottom: 0px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt50 {
  margin-top: 50px;
}

.mt175 {
  margin-top: 175px;
}


.text-orng {
  color: #fa9029 !important;
}

.text-red {
  color: #ff0000 !important;
}

.text-required {
  color: #ff0000;
  font-style: italic;
  margin-top: 5px;
  font-size: 14px;
}



.page-link {
  color: #2f3c4e;
}

.page-item.active .page-link {
  background-color: #ef4e57;
  border-color: #ef4e57;
}

.react-bootstrap-table-pagination {
  margin-top: 20px;
}

.dropdown-item a {
  color: #2f3c4e !important;
}



.linkCurson {
  cursor: pointer;
}

.textUnderline {
  text-decoration: underline;
}


.pointsInline .list-inline-item:not(:last-child) {
  border-right: 1px solid #fff;
  padding-right: 8px;
  margin-bottom: 6px;
  margin-top: 6px;
}



.selSampleType {
  border: none;
  width: auto;
}

.inputQty {
  width: 5px !important;
  padding: 0px;
  text-align: center;
}

.btnQty {
  padding: 4px 10px;
  border-color: #dee2e6;
  background-color: #e5f1ff;
}

.btn-reprint,
.btn-reject,
.btn-yes {
  padding: 2px 6px;
}

.btn-yes {
  background-color: #fff0f0 !important;
  border-color: #fff0f0 !important;
  color: #ef4e57;
}

.btn-yes:hover,
.btn-yes:focus,
.btn-yes:active {
  background-color: #ef4e57 !important;
  border-color: #ef4e57 !important;
  color: #fff !important;
}

.btn-reprint {
  background-color: #e8f8f8 !important;
  border-color: #e8f8f8 !important;
  color: #55b8b2;
}

.btn-reprint:hover,
.btn-reprint:focus,
.btn-reprint:active {
  background-color: #01bed9 !important;
  border-color: #01bed9 !important;
  color: #fff !important;
}

.btn-reject {
  background-color: #fef4e8 !important;
  border-color: #fef4e8 !important;
  color: #ff8918;
}

.btn-reject:hover,
.btn-reject:focus,
.btn-reject:active {
  background-color: #ff8918 !important;
  border-color: #ff8918 !important;
  color: #fff !important;
}



.colorCode1,
.colorCode2,
.colorCode3,
.colorCode4 {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: solid 1px #d5d5d5;
}

.colorCode2 {
  background-color: #fd686b;
}

.colorCode3 {
  background-color: #ff8918;
}

.colorCode4 {
  background-color: #1cbdbf;
}

.signinLogoHeader {
  background-color: #e31e25;
  padding: 10px 0 0 0;
  text-align: center;
}

.logoTagLine {
  background-color: #fff;
  text-align: center;
  font-size: 9px;
  color: #000;
  padding: 1px;
}

.logoTagLine span {
  color: #e31e25;
  font-weight: bold;
}

.main-header-left {
  background-color: #e31e25 !important;
}

.summaryTableClass {
  table-layout: auto;
  white-space: nowrap;
  overflow-x: auto;
}

.summaryTableClass .react-bootstrap-table table {
  table-layout: auto;
}

.OrderDetailClass .react-bootstrap-table .table {
  table-layout: auto;
  width: 890px;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.PrintDayEndReport .react-bootstrap-table .table {
  table-layout: auto;
  width: 1600px;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.printReportContainerModel {
  max-width: 1400px;
  width: 100%;
}

.searchButtonSec {
  display: flex;
  align-items: center;
  height: 100px;
}


/******* Mobile******/
@media only screen and (min-device-width:320px) and (max-device-width:767px) {
  .page-main-header .main-header-right svg line {
    color: #fff;
  }

}


@media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:portrait) {}


@media only screen and (min-device-width:320px) and (max-device-width:767px) and (orientation:landscape) {}




/****** IPADs *********/
@media only screen and (min-device-width:768px) and (max-device-width:1024px) {}



@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) {
  .page-main-header .main-header-right svg line {
    color: #fff;
  }

  .page-main-header .main-header-right .nav-right>ul>li:first-child {
    width: 80%;
  }

}


@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) {}




/* (1920x1080) Full HD Display */
/*@media  screen and (max-width: 1920px)*/
@media screen and (min-width: 1920px) {}





/* iPhone X in portrait & landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  /* STYLES GO HERE */
}


/* iPhone X in portrait */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {}


/* iPhone X in landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {}





/*iPhone 6, 7, & 8 portrait & landscape*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 landscape*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 portrait & landscape*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : portrait) {
  /* STYLES GO HERE */
}



/*iPhone 6, 7, & 8 Plus in portrait & landscape*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 Plus in  landscape*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : landscape) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 Plus in portrait*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : portrait) {
  /* STYLES GO HERE */
}



/*iPhone 5 & 5S in portrait & landscape*/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  /* STYLES GO HERE */
}


/*iPhone 5 & 5S in landscape*/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) {
  /* STYLES GO HERE */
}


/*iPhone 5 & 5S in portrait*/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait) {
  /* STYLES GO HERE */
}




@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-width:1224px) {}

@media only screen and (min-width:1824px) {}

@media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:568px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:568px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:375px) and (max-device-height:667px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:375px) and (max-device-height:667px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:414px) and (max-device-height:736px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:414px) and (max-device-height:736px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:3) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:3) {}

@media only screen and (min-device-width:360px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:3) {}

@media only screen and (min-device-width:360px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:3) {}

@media screen and (-webkit-min-device-pixel-ratio:0) {}

@media screen and (min-device-width: 1701px) and (max-device-width: 2034px) {
  .summaryTableClass {
    width: 100%;
    max-width: 100%;
    table-layout: auto;
    white-space: nowrap;
    overflow-x: auto;
  }
}

@media only screen and (max-width: 1700px) {
  .summaryTableClass {
    table-layout: auto;
    max-width: 1700px;
    width: auto;
    display: block;
    white-space: nowrap;
    overflow-x: auto;
  }
}

.page-wrapper .page-body-wrapper .page-header .row h3 {
  text-transform: none;
}

/************10-05-2022***********/

/* input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
} */
/************21-02-2022***********/

.stepper-wrapper * {
  box-sizing: border-box;
}

.stepper-wrapper .stepper-head {
  display: flex;
  position: relative;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* styles of inline stepper */
  /* styles of vertical stepper */
}

.stepper-wrapper .stepper-head.inline-stepper-head .stepper-step:after {
  top: 50%;
  transform: translateY(-50%);
}

.stepper-wrapper .stepper-head.inline-stepper-head .stepper-indicator,
.stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
  display: inline-block;
  vertical-align: middle;
}

.stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
  text-align: left;
  padding: 0 0.5rem;
  background: #fff;
}

.stepper-wrapper .stepper-head.vertical-stepper-head {
  flex-direction: column;
  width: auto;
}

.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step {
  display: flex;
  align-items: center;
  text-align: start;
}

.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step:after {
  content: " ";
  position: absolute;
  left: 1.5rem;
  right: auto;
  top: 2rem;
  bottom: -0.5rem;
  width: 0;
  height: auto;
  border-left: 0.125rem solid #e3e8ec;
}

.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step.rightToLeft:after {
  right: 1.5rem;
  left: auto;
}

.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-label {
  -webkit-padding-start: 1rem;
  padding-inline-start: 1rem;
}

.stepper-wrapper .stepper-head.vertical-stepper-head+.stepper-body {
  -webkit-margin-start: 3.5rem;
  margin-inline-start: 3.5rem;
}

.stepper-wrapper .stepper-head .stepper-step {
  position: relative;
  text-align: center;
  padding: 0.5rem;
  flex-basis: 100%;
}

.stepper-wrapper .stepper-head .stepper-step.rightToLeft:after {
  left: -50%;
}

.stepper-wrapper .stepper-head .stepper-step.is-complete .stepper-indicator-info {
  border-color: #4caf50;
  background-color: #4caf50;
  cursor: pointer;
}

.stepper-wrapper .stepper-head .stepper-step.is-complete .stepper-label {
  color: #4caf50;
}

.stepper-wrapper .stepper-head .stepper-step.is-active .stepper-indicator-info {
  border-color: #627c90;
  background-color: #627c90;
  cursor: initial;
}

.stepper-wrapper .stepper-head .stepper-step.is-active .stepper-label {
  color: #627c90;
}

.stepper-wrapper .stepper-head .stepper-step.is-warning .stepper-indicator-info {
  border-color: #f1c40f;
  background-color: #f1c40f;
}

.stepper-wrapper .stepper-head .stepper-step.is-warning .stepper-label {
  color: #f1c40f;
}

.stepper-wrapper .stepper-head .stepper-step.is-error .stepper-indicator-info {
  border-color: #e95a4b;
  background-color: #e95a4b;
}

.stepper-wrapper .stepper-head .stepper-step.is-error .stepper-label {
  color: #e95a4b;
}

.stepper-wrapper .stepper-head .stepper-step:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 1.5rem;
  width: 100%;
  height: 0.125rem;
  background-color: #e3e8ec;
  z-index: 1;
}

.stepper-wrapper .stepper-head .stepper-step:last-child:after {
  display: none;
}

.stepper-wrapper .stepper-head .stepper-indicator {
  position: relative;
  display: block;
  z-index: 2;
}

.stepper-wrapper .stepper-head .stepper-indicator-info {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #e3e8ec;
  border-radius: 50%;
  background-color: #e3e8ec;
  font-size: 1rem;
  line-height: 2.5rem;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.stepper-wrapper .stepper-head .stepper-indicator-info .stepper-tick {
  width: 14px;
  fill: #ffffff;
}

.stepper-wrapper .stepper-head .stepper-label {
  position: relative;
  display: block;
  margin: 0.5rem 0;
  color: #cfd7de;
  z-index: 2;
}

.stepper-wrapper .stepper-body {
  flex: 1;
  min-height: 100px;
  padding: 10px 0;
}

.stepper-wrapper .stepper-footer {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.stepper-wrapper .stepper-footer-btn {
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  cursor: pointer;
  border: none;
  outline: none;
}

.stepper-wrapper .stepper-footer-btn.primary {
  color: #fff;
  background-color: #1976d2;
}

.stepper-wrapper .stepper-footer-btn.success {
  color: #fff;
  background-color: #4caf50;
}

.stepper-wrapper .stepper-footer-btn:disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}




.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a {
  color: #000 !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg {
  color: #000 !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg path,
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg line {
  color: #000 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #000 !important;
}

.pagination-primary .page-item.active .page-link {
  background-color: #e31e25;
  border-color: #e31e25;
}

h5,
.h5 {
  color: #e31e25;
}


.btn-primary {
  background-color: #e31e25 !important;
  border-color: #e31e25 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: #cb1118 !important;
  border-color: #cb1118 !important;
}


.signinLogoHeader {
  background-color: #e31e25;
  padding: 10px 0 0 0;
  text-align: center;
}

.logoTagLine {
  background-color: #fff;
  text-align: center;
  font-size: 9px;
  color: #000;
  padding: 1px;
}

.logoTagLine span {
  color: #e31e25;
  font-weight: bold;
}

.main-header-left {
  background-color: #e31e25 !important;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-border {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



/************** DatePicker css**************/

.dateIcon {
  position: relative;
}

.dateIcon .fa-calendar,
.dateIcon .fa-clock-o {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -6px;
}

.react-datepicker_time-container .react-datepickertime .react-datepicker_time-box {
  width: 90px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 90px !important;
}

.padrevert {
  padding: revert;
  margin-left: 20px;
}

/***This is to increase width of patient search in registation page.As discussed with shiv sir this width is temporary and he will fix this width responsiveness afterwards.***/
.colorBox {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  font-weight: bold;
  padding: 5px;
  margin-top: 30px;
  margin-left: 10px;
  padding-left: 10px;
}


/********************Kavish CSS *******************/
.raFilter {
  margin-top: 9px;
  font-weight: 600;
}

/***Kavish Raghav***/
.advancepaymentradio label {
  display: inline-block;
  margin-bottom: 0.5rem;
  padding: 5px 5px;
}

.advancepaymentradio label input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  margin-right: 5px;
  height: 12px;
  line-height: 22px;
}

/***Kavish Raghav***/
.progresWrapper {
  padding: 50px 60px 60px 60px;
  min-height: 160px;
}

.RSPBprogressBar {
  height: 10px;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color: rgba(211, 211, 211, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
}

.RSPBprogressBar .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.stepLabel {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  margin-top: 30px;
  margin-left: -84px;
  width: 190px;
  text-align: center;
}

.RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: rgba(0, 116, 217, 0.8);
  z-index: -1;
}


.searchCloseIcon {
  position: absolute;
  right: 22px;
}




/***Shiv Sir on 09 Feb 2023***/
.cartAccordian {
  position: relative;
  padding: 20px 0px !important;
}

.cartAccordian .card {
  border: none;
  box-shadow: none;
  margin-bottom: 1px;
}

.cartAccordian .card .card-header {
  padding: 0px;
  border: none;
  /*background: #e9ecef;*/
  background: #f4f4f4;
}

.cartAccordian .btn-link {
  color: #212529;
  font-weight: 700;
  text-decoration: none;
  text-align: left;
  padding: 12px 16px;
  display: block;
  width: 100%;
}

.accordionheaderleft {
  display: block;
  width: 70%;
  float: left;
  font-size: 16px;
}

.accordionheaderright {
  display: block;
  width: 30%;
  float: right;
  justify-content: end;
  text-align: right;
  padding-right: 20px;
}

.accordionheaderright span {
  color: #999999;
  min-width: 30px;
  display: block;
}

.accordionheaderright span {
  color: #999999;
  display: block;
  float: right;
  text-align: right;
  margin-right: 10px;
}

.cartsec {
  display: block;
  width: 100%;
  height: auto;
  background: #FFFFFF;
}

.cartsec1 {
  display: block;
  width: 100%;
  height: auto;
  background: #FFFFFF;
  padding: 15px 30px;
  border-radius: 8px;
}

.carttitlesec {
  display: block;
  width: 100%;
  height: auto;
  padding-top: 16px;
  padding-left: 20px;
}

.carttitlesec h2 {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 19px !important;
  color: #000000 !important;
}

.carttitlesec p {
  font-weight: 400;
  line-height: 19px;
  color: #999999;
}

.cartmainsec span {
  color: #999999;
  padding-left: 5px;
}

.cartsec .cartfootersec {
  width: 100%;
  padding: 27px;
  display: flex;
  justify-content: right;
}

.cartsec .cartfootersec button {
  border: none;
  background-color: #000000;
  color: #ffffff;
  padding: 7px 20px;
}

.cartmainsec ul {
  list-style: none;
  padding: 0;
}

.cartmainsec ul li {
  font-size: 15px;
  color: black;
  font-weight: 500;
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.cartmainsec ul li div {
  display: block;
  float: left;
  width: 12px;
  height: 50px;
}

.cartmainsec ul li p {
  color: #999999;
  font-size: 15px;
  font-weight: 400;
}

.cartmainsec ul li div i {
  display: block;
  border: 1px solid #E31E25;
  width: 17px;
  height: 16px;
  text-align: center;
  padding-top: 1px;
  color: #E31E25;
  font-size: 12px;
  margin-top: 5px;
}

.cartmainsec span {
  color: #999999;
  padding-left: 5px;
}

.cartmainsec .salePrice {
  font-size: 15px;
  color: #e31e25;
  font-weight: 500;
}

.cartmainsec .comparePrice {
  font-size: 15px;
  color: #999;
  font-weight: 500;
  text-decoration: line-through;
}

.cartmainsec .offPrice {
  font-size: 15px;
  color: #339933;
  font-weight: 500;
}

.cartTextBlack {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0c0c0c;
}

.findCenter .cartTextBlackNext {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0c0c0c;
  margin-bottom: 20px;
}

.cartmainsec .card .card-header {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

/***Shiv Sir on 09 Feb 2023***/

.accordionheaderleft span {
  font-size: 14px;
  font-weight: 500;
}

/* .accordion-button::after { flex-shrink: 0; width: 1.25rem; height: 1.25rem; margin-left: auto; content: ""; background-image: url("/images/accodianArrowIconBlack.svg"); background-repeat: no-repeat; background-size: 1.25rem; transition: transform 0.2s ease-in-out;transform: rotate(-93deg);} */

/* .cartAccordian .btn-link:after{ content: ""; position: absolute; right: 12px;  width: 1.25rem; height: 1.25rem; background-image: url("/images/accodianArrowIconBlack.svg"); background-repeat: no-repeat; background-size: 1.25rem; transition: transform 0.2s ease-in-out;}
.cartAccordian .btn-link.active:after{    transform: rotate(-180deg);} */

.fw-bold {
  font-weight: 700 !important;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}


.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.calandericon1 {
  position: absolute;
  top: 50%;
  right: 26px !important;
  margin-top: -4px !important;
}

.text-patientName {
  font-size: 16px;
}

.ageGender {
  font-size: 14px;
}

.ft18 {
  font-size: 18px;
}

.secWrapper .text-required {
  position: absolute;
}

.card .card-header {
  padding: 15px 30px;
}




.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, transition;
}


/* Carausel slider start */
.react-multiple-carousel__arrow::before {
  font-size: 25px;
  color: #000;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: #e31e25;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}

.react-multiple-carousel__arrow:hover {
  background: #000;
}

/* Carausel slider End */

.react-multi-carousel-list .react-multiple-carousel__arrow--left {
  left: calc(-1% + 15px);
}

.react-multi-carousel-list .react-multiple-carousel__arrow--right {
  right: calc(-1% + 15px);
  content: '>'
}

.react-multi-carousel-list .react-multiple-carousel__arrow--left::after {
  color: #fff;
  font-family: FontAwesome;
  content: '\f060';
}

.react-multi-carousel-list .react-multiple-carousel__arrow--right::after {
  color: #fff;
  font-family: FontAwesome;
  content: '\f061';
}

.swal2-title {
  font-size: 30px !important;
}

.swal2-content {
  font-size: 16px !important;
  line-height: 1.5 !important;
}

.headingstyle3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.textfullwidth {
  display: inline-block;
  width: 100%;
}

.adPatientColLeft {
  padding-right: 20px !important;
}

.adPatientColRight {
  border-left: solid 1px #dee2e6;
  padding-left: 60px !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.adPatientCol {
  border-left: none !important;
  border-top: none;
  padding-left: 0px !important;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  padding-bottom: 0px !important;
}

.adPatientColLeft #inpt {
  justify-content: center;
}

/**********17 March 2013 **************/
.hdRoute {
  font-size: 22px;
  color: #ef4e57;
  margin-bottom: 0px;
}

.routeSN {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
}

.routeCard {
  position: relative;
}

.routeCard .card {
  background: #fff;
}

.routeCard .card-header {
  background: #E9ECEF;
  padding: 5px 30px !important;
}

.routeCard .card-header .fa-ellipsis-h {
  color: #999999;
}

.routeCard .card-header .btn-link {
  padding: 6px !important;
}

.routeCard .card-header .dropdown-toggle::after {
  border: 0px !important;
}

.routeCard .card-header .dropdown-menu {
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
  margin-top: -10px;
}

.routeCard .card-header .dropdown-menu .icofont {
  font-size: 20px;
}

.routeCard .card-header .dropdown-item.active,
.routeCard .card-header .dropdown-item:active {
  background-color: #e31e25;
}

.routeCard .card-body p {
  font-size: 13px;
  margin-bottom: 5px;
}

.routeCard .card-footer {
  padding: 15px 30px;
  text-align: center;
}

.colorCodeTB {
  position: relative;
}

.colorCodeTB,
.colorCodeTB .form-control {
  font-size: 14px !important;
}

.colorCodeTB .form-check-label {
  display: inline-block;
  margin-top: 3px;
}

.colorCodeTB td {
  vertical-align: middle !important;
  padding: 20px 16px;
}



.react-datepicker .react-datepicker__day--today {
  background-color: #fff !important;
  color: #000 !important;
}

.react-datepicker .react-datepicker__day--selected {
  background-color: #e31e25 !important;
  color: #fff !important;
}

.discountBx {
  background: #e9ecef;
  padding: 15px 15px;
  margin-top: 20px;
}

#tbLedger td:nth-child(9),
#tbLedger td:nth-child(10),
#tbLedger th:nth-child(9),
#tbLedger th:nth-child(10) {
  background: #FFE0E6;
}

#tbLedger td:nth-child(11),
#tbLedger td:nth-child(12),
#tbLedger th:nth-child(11),
#tbLedger th:nth-child(12) {
  background: #FFE9B3;
}

#tbLedger td:nth-child(13),
#tbLedger td:nth-child(14),
#tbLedger th:nth-child(13),
#tbLedger th:nth-child(14) {
  background: #DDFFF7;
}

#tbLedger td:nth-child(15),
#tbLedger td:nth-child(16),
#tbLedger th:nth-child(15),
#tbLedger th:nth-child(6) {
  background: #F4EFE6;
}


.tbInvoice {
  border: solid 1px #000;
  font-size: 16px;
  line-height: 1.4;
}

.tdHeaderInvoice {
  padding: 10px;
}

.tdHeaderInvoice h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
}

.taxInvoice {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
}

.brdrTop {
  border-top: solid 1px #000;
}

.brdrBottom {
  border-bottom: solid 1px #000;
}

.brdrLeft {
  border-left: solid 1px #000;
}

.brdrRight {
  border-right: solid 1px #000;
}

.p-10 {
  padding: 10px;
}

.txtUnderline {
  text-decoration: underline;
}

.tbValueDiscount tr td {
  padding: 5px 10px;
  font-weight: bold;
}

.tbItem tr td {
  padding: 5px 10px;
}

#html5qr-code-full-region img[alt='Info icon'] {
  display: none !important;
}

#html5qr-code-full-region {
  border: 1px solid #e6e6e6;
}

#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}

#html5-qrcode-button-camera-permission {
  background-color: #e31e25 !important;
  border-color: #e31e25 !important;
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  letter-spacing: 0.7px;
  color: #fff;
  border-radius: 0.25rem;
}

@media print {

  table.tbInvoice,
  table.tbInvoice tr td,
  table.tbInvoice tr th {
    page-break-inside: avoid !important;
    page-break-before: avoid !important;
    page-break-after: avoid !important;
  }
}


/******* Mobile******/
@media only screen and (min-device-width:320px) and (max-device-width:767px) {
  .page-main-header .main-header-right svg line {
    color: #fff;
  }

  .react-bootstrap-table table {
    overflow-x: auto !important;
    display: block;
    table-layout: auto !important;
  }

  .adPatientColRight {
    border-left: none !important;
    border-top: none;
    padding-left: 15px !important;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
    padding-bottom: 0px !important;
  }

  .adPatientColLeft {
    padding-right: 15px !important;
  }

  .adPatientCol {
    border-left: none !important;
    border-top: none;
    padding-left: 0px !important;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
    padding-bottom: 0px !important;
  }

  .cartAccordian .card .card-header {
    padding: 0px !important;
  }

  .inputSin {
    width: 120px;
  }

  .page-wrapper .page-main-header .main-header-right .nav-right>ul .search-form .form-control-plaintext {
    width: 310px !important;
  }

  #userModule {
    width: 155px !important;
  }

  .ProfileCard-details {
    float: none !important;
    padding-left: 0px !important;
    text-align: left;
  }

  .mobile-search svg line {
    color: #e31e25 !important;
  }

  .searchCloseIcon {
    position: absolute;
    right: -270px;
    top: 72px
  }

  .ulSelTime {
    margin-left: 15px !important;
  }

  .dbSelTime {
    width: auto !important;
  }

  #pageDropDown {
    margin-bottom: 15px !important;
  }

}


@media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:portrait) {}


@media only screen and (min-device-width:320px) and (max-device-width:767px) and (orientation:landscape) {}




/****** IPADs *********/
@media only screen and (min-device-width:768px) and (max-device-width:1024px) {}



@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) {
  .page-main-header .main-header-right svg line {
    color: #fff;
  }

  .page-main-header .main-header-right .nav-right>ul>li:first-child {
    width: 80%;
  }

  .react-bootstrap-table table {
    overflow-x: auto !important;
    display: block;
    table-layout: auto !important;
  }

  .cartAccordian .card .card-header {
    padding: 0px !important;
  }

  .inputSin {
    width: 120px;
  }

  .adPatientColRight {
    padding-left: 38px !important;
  }

}


@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) {
  .logoTagLine {
    padding: 1px;
  }
}




/* (1920x1080) Full HD Display */
/*@media  screen and (max-width: 1920px)*/
@media screen and (min-width: 1920px) {}


@media only screen and (max-width: 991px) {
  .page-main-header .main-header-right {
    background-color: #e30016;
  }
}



@media only screen and (max-width: 991px) {
  .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    height: 42px;
    display: block;
    margin: 0 auto;
  }

  .main-header-left {
    width: 255px;
  }

  .page-main-header .main-header-right {
    padding-left: 0px;
  }

  /*.logoTagLine{padding: 1px; font-size: 8px;}*/



}




/* iPhone X in portrait & landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  /* STYLES GO HERE */
}


/* iPhone X in portrait */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {}


/* iPhone X in landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {}





/*iPhone 6, 7, & 8 portrait & landscape*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 landscape*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 portrait & landscape*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : portrait) {
  /* STYLES GO HERE */
}



/*iPhone 6, 7, & 8 Plus in portrait & landscape*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 Plus in  landscape*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : landscape) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 Plus in portrait*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : portrait) {
  /* STYLES GO HERE */
}



/*iPhone 5 & 5S in portrait & landscape*/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  /* STYLES GO HERE */
}


/*iPhone 5 & 5S in landscape*/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) {
  /* STYLES GO HERE */
}


/*iPhone 5 & 5S in portrait*/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait) {
  /* STYLES GO HERE */
}




@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-width:1224px) {}

@media only screen and (min-width:1824px) {}

@media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:568px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:568px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:375px) and (max-device-height:667px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:375px) and (max-device-height:667px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:414px) and (max-device-height:736px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:414px) and (max-device-height:736px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:3) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:3) {}

@media only screen and (min-device-width:360px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:3) {}

@media only screen and (min-device-width:360px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:3) {}

@media screen and (-webkit-min-device-pixel-ratio:0) {}

.blink {
  animation: blinker .75s linear infinite;
  color: red;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding: 8px 16px;
}

.modalvariation {
  height: 100%;
  max-height: 800px !important;
  overflow: scroll !important;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink .fa {
  font-size: 26px;
}


.btn-downloadExcel {
  padding: 0px;
  color: #e31e25;
  text-decoration: none;
}

.btn-downloadExcel:hover {
  color: #000;
  text-decoration: none;
}

.blink-new {
  font-size: 14px;
  background-color: #ffe45f;
  padding: 5px 8px;
}

.blink-new1 {
  font-size: 20px;
  background-color: #ffe45f;
  padding: 5px 8px;
}

.paymentProgress {
  position: relative;
  margin-top: 100px;
  text-align: center;
}

.paymentProgress h2 {
  font-size: 28px;
  font-weight: 600;
}

.paymentProgress p {
  font-size: 14px;
}

@media print {
  .stickerresp {
    font-size: 50px !important;
  }

  .departresp {
    font-size: 25px !important;
  }

  .departresp table {
    font-size: 18px !important;
  }
}


.pointList {
  position: relative;
  font-size: 16px;
}

.pointList li {
  margin-bottom: 12px;
}

.pointList li a {
  color: #313131;
  text-decoration: none;
}

.pointList li a:hover {
  color: #ef4e57;
  text-decoration: underline;
}

.bill-container .footer p {
  font-size: 12px;
  color: #555;
}

.bill-container .footer p strong {
  font-size: 14px;
  color: #000;
}

.bill-container p {
  margin-bottom: 10px !important;
}

/* #crsl .carousel-indicators{
  bottom: 75px;
} */
#crsl h3 {
  margin-top: 25px;
  font-size: 22px;
  font-weight: 600;
}

.textCarousel {
  position: relative;
}

/* .textCarousel{ min-height: 250px; box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);} */

#textCarousel {
  height: 170px;
}

#crsl .carousel-indicators {
  bottom: 0px !important;
  z-index: 1;
}

#crsl .carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.1);
}

#crsl .carousel-indicators .active {
  background: red !important;
}

#crsl h3 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
}

#crsl h1 {
  font-size: 32px;
  font-weight: 500;
}

#crsl .react-multi-carousel-dot-list {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 15px;
}

#crsl .react-multi-carousel-dot button {
  width: 20px;
  height: 6px;
  border: none;
  background: #d9d9d9;
}

#crsl .react-multi-carousel-dot--active button {
  background: #e30016;
}

.imgCarousel {
  width: 100%;
  height: 250px;
  margin-bottom: 25px;
  cursor: pointer;
}

.imgCarousel img {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
}

.imgCarousel:hover .img-thumbnail {
  border: 1px solid #e31e25;
}

.effectZoom2 {
  transition: transform 0.2s;
}

.effectZoom2:hover {
  transform: scale(1.1);
}

#sampleRw {
  margin-bottom: 20px;
}

#sampleRw .list-inline-item:not(:last-child) {
  margin-right: 20px;
}

.sampleColors {
  position: relative;
  display: flex;
  align-items: center;
}

.sampleColors .sampleBox {
  position: relative;
  width: 30px;
  height: 22px;
  border: solid 1px #a1a0a0;
  margin-right: 5px;
}

.sampleColors .sampleText {
  font-size: 16px;
  margin-top: 2px;
}

.sampleColors .sampleBox.all {
  background-color: #FFFFFF;
}

.sampleColors .sampleBox.new {
  background-color: #C286FC;
}

.sampleColors .sampleBox.sampleCollected {
  background-color: #F7E8C7;
}

.sampleColors .sampleBox.departmentReceive {
  background-color: #F0BDCC;
}

.sampleColors .sampleBox.reportGenerated {
  background-color: #ADFE93;
}

.sampleColors .sampleBox.rejected {
  background-color: #CCCCCC;
}

.sampleColors .sampleBox i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #e31e25;
}

.nwCarouselWrap {
  position: relative;
  border: solid 1px #d9d9d9;
  padding: 20px;
  margin: 0px 15px;
}

.nwCarouselWrap .nwCarouselHeading {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.nwCarouselWrap .nwCarouselThumb {
  width: 100%;
  height: 150px;
  object-fit: contain;
  object-position: cover;
  overflow: hidden;
}

/* .nwCarouselWrap .nwCarouselThumb img{ max-width: 100%; height: auto;} */
.pdremark {
  padding: 5px 10px;
}

.remarksection1 {
  height: 380px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.remarksection2 {
  display: flow-root !important;
  border-top: none !important;
}